import React from 'react';
import './Callnow.css';

const Callnow = () => {
  return (
  <div className="call_btn_now">
    <a href="tel:+919404929492">
        Call Now
    </a> 
  </div>
  );
};

export default Callnow;
